exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-navigation-nav-bar-tsx": () => import("./../../../src/pages/components/Navigation/NavBar.tsx" /* webpackChunkName: "component---src-pages-components-navigation-nav-bar-tsx" */),
  "component---src-pages-components-navigation-user-menu-tsx": () => import("./../../../src/pages/components/Navigation/UserMenu.tsx" /* webpackChunkName: "component---src-pages-components-navigation-user-menu-tsx" */),
  "component---src-pages-components-section-tsx": () => import("./../../../src/pages/components/Section.tsx" /* webpackChunkName: "component---src-pages-components-section-tsx" */),
  "component---src-pages-components-seo-tsx": () => import("./../../../src/pages/components/seo.tsx" /* webpackChunkName: "component---src-pages-components-seo-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-layout-tsx": () => import("./../../../src/pages/layout.tsx" /* webpackChunkName: "component---src-pages-layout-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-sections-contact-button-tsx": () => import("./../../../src/pages/sections/Contact/Button.tsx" /* webpackChunkName: "component---src-pages-sections-contact-button-tsx" */),
  "component---src-pages-sections-contact-contact-tsx": () => import("./../../../src/pages/sections/Contact/Contact.tsx" /* webpackChunkName: "component---src-pages-sections-contact-contact-tsx" */),
  "component---src-pages-sections-contact-input-tsx": () => import("./../../../src/pages/sections/Contact/Input.tsx" /* webpackChunkName: "component---src-pages-sections-contact-input-tsx" */),
  "component---src-pages-sections-footer-footer-tsx": () => import("./../../../src/pages/sections/Footer/Footer.tsx" /* webpackChunkName: "component---src-pages-sections-footer-footer-tsx" */),
  "component---src-pages-sections-future-future-tsx": () => import("./../../../src/pages/sections/Future/Future.tsx" /* webpackChunkName: "component---src-pages-sections-future-future-tsx" */),
  "component---src-pages-sections-hero-hero-tsx": () => import("./../../../src/pages/sections/Hero/Hero.tsx" /* webpackChunkName: "component---src-pages-sections-hero-hero-tsx" */),
  "component---src-pages-sections-hero-newsletter-tsx": () => import("./../../../src/pages/sections/Hero/Newsletter.tsx" /* webpackChunkName: "component---src-pages-sections-hero-newsletter-tsx" */),
  "component---src-pages-sections-mission-mission-tsx": () => import("./../../../src/pages/sections/Mission/Mission.tsx" /* webpackChunkName: "component---src-pages-sections-mission-mission-tsx" */),
  "component---src-pages-sections-products-our-product-tsx": () => import("./../../../src/pages/sections/Products/OurProduct.tsx" /* webpackChunkName: "component---src-pages-sections-products-our-product-tsx" */),
  "component---src-pages-sections-products-product-tsx": () => import("./../../../src/pages/sections/Products/Product.tsx" /* webpackChunkName: "component---src-pages-sections-products-product-tsx" */),
  "component---src-pages-sections-products-products-tsx": () => import("./../../../src/pages/sections/Products/Products.tsx" /* webpackChunkName: "component---src-pages-sections-products-products-tsx" */),
  "component---src-pages-sections-video-button-collapse-tsx": () => import("./../../../src/pages/sections/Video/ButtonCollapse.tsx" /* webpackChunkName: "component---src-pages-sections-video-button-collapse-tsx" */),
  "component---src-pages-sections-video-button-expand-tsx": () => import("./../../../src/pages/sections/Video/ButtonExpand.tsx" /* webpackChunkName: "component---src-pages-sections-video-button-expand-tsx" */),
  "component---src-pages-sections-video-player-tsx": () => import("./../../../src/pages/sections/Video/Player.tsx" /* webpackChunkName: "component---src-pages-sections-video-player-tsx" */),
  "component---src-pages-sections-video-summary-tsx": () => import("./../../../src/pages/sections/Video/Summary.tsx" /* webpackChunkName: "component---src-pages-sections-video-summary-tsx" */),
  "component---src-pages-sections-video-video-tsx": () => import("./../../../src/pages/sections/Video/Video.tsx" /* webpackChunkName: "component---src-pages-sections-video-video-tsx" */),
  "component---src-pages-sections-who-avatar-tsx": () => import("./../../../src/pages/sections/Who/Avatar.tsx" /* webpackChunkName: "component---src-pages-sections-who-avatar-tsx" */),
  "component---src-pages-sections-who-person-tsx": () => import("./../../../src/pages/sections/Who/Person.tsx" /* webpackChunkName: "component---src-pages-sections-who-person-tsx" */),
  "component---src-pages-sections-who-who-tsx": () => import("./../../../src/pages/sections/Who/Who.tsx" /* webpackChunkName: "component---src-pages-sections-who-who-tsx" */)
}

